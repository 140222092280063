import Firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

const config = {
    apiKey: "AIzaSyADVn99RQjHhy4-wn5iPr255hhHG6ysXcU",
    authDomain: "moviesx-200f1.firebaseapp.com",
    projectId: "moviesx-200f1",
    storageBucket: "moviesx-200f1.appspot.com",
    messagingSenderId: "188729855937",
    appId: "1:188729855937:web:2c9b9c3ca04ca80c06e940"
};

const firebase = Firebase.initializeApp(config);

export { firebase };
